import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../application/service/auth'

const PrivateRoute = ({ children, ...rest }): JSX.Element => {
  const { token } = useAuth()

  return (
    <Route
      {...rest}
    >
      {token
        ? children
        : <Redirect to={{ pathname: '/login' }} />
      }
    </Route>
  )
}

export default PrivateRoute
