import React, { useEffect } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom'
import { useAuth } from '../../../application/service/auth';

function Login() {
  const { getToken, goToLogin, token } = useAuth()
  const location = useLocation<{ [key: string]: unknown }>()
  const currentLocationState = location.state
    ? location.state
    : { from: { pathname: '/' } }

  const hash = window.location.hash;
  const params = new URLSearchParams(hash.split('?')[1]); 
  const code = params.get('code');

  if (token) {
    return <Redirect to={'/'} />
  }

  if (code) {
    getToken(code)
  } else {
    goToLogin()
  }

  return <div/>
}

export default Login;
