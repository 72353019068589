// @ts-nocheck
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeConfig } from '../config/make-config';

interface AuthHookReturn {
  token: string | null;
  getToken: (code: string) => Promise<void>;
  logout: () => void;
  goToLogin: () => void;
  user: any
  roles: string[]
  getUserInfo: () => Promise<void>
}

export const useAuth = (): AuthHookReturn => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [tokenId, setTokenId] = useState<string | null>(localStorage.getItem('tokenId'));
  const [expiresIn, setExpiresIn] = useState<number>(
    parseInt(localStorage.getItem('expiresIn') || '0', 10)
  );
  const [user, setUser] = useState<any>(localStorage.getItem('user'));
  const [roles, setRoles] = useState<string>(['ADMIN']);
  const history = useHistory();

  const logout = () => {
    const { baseUrl, signInRedirectURL, clientSecret } = makeConfig();
    setToken(null);
    setTokenId(null);
    setUser(null)
    localStorage.removeItem('tokenId')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.href = `${baseUrl}/oidc/logout?post_logout_redirect_uri=${encodeURIComponent(signInRedirectURL)}&id_token_hint=${tokenId}`
  };

  const getToken = async (code: string) => {
    const { baseUrl, clientID, signInRedirectURL, clientSecret } = makeConfig();
    const requestParams = new URLSearchParams({
      code: String(code),
      grant_type: 'authorization_code',
      client_id: String(clientID),
      client_secret: String(clientSecret),
      redirect_uri: String(signInRedirectURL),
    });

    try {
      const response = await fetch(`${baseUrl}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: requestParams.toString(),
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      const expirationTime = Date.now() + data.expires_in * 1000;

      localStorage.setItem('token', data.access_token);
      localStorage.setItem('tokenId', data.id_token);
      localStorage.setItem('expiresIn', String(expirationTime));
      setToken(data.access_token);
      setTokenId(data.id_token);
      setExpiresIn(expirationTime);

      history.push('/');
    } catch (error) {
      console.error('Erro na requisição:', error);
      goToLogin()
    }
  };

  
  const getUserInfo = async (code: string) => {
    const { baseUrl,  } = makeConfig();

    try {
      const response = await fetch(`${baseUrl}/oauth2/userInfo`, {
        method: 'GET',
        headers: {
          'Authentication': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      localStorage.setItem('user', data);
      setUser(data);
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  const checkTokenExpiration = () => {
    const currentTime = Date.now();
    if (expiresIn && currentTime > expiresIn) {
      logout();
    }
  };

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 10000);
    return () => clearInterval(interval);
  }, [expiresIn]);

  const goToLogin = () => {
    const { baseUrl, clientID, scope, signInRedirectURL } = makeConfig();
    window.location.href = `${baseUrl}/oauth2/authorize?response_type=code&client_id=${clientID}&scope=${scope}&redirect_uri=${encodeURIComponent(signInRedirectURL)}`;
  };

  return { token, getToken, logout, goToLogin, user, roles, getUserInfo };
};
