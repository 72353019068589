export function isColgate() {
  const origin = window.location.origin
  const [_, host] = origin.split("://")
  return host === process.env.WSO2_COLGATE_DOMAIN
}

export function isFiorde() {
  const origin = window.location.origin
  const [_, host] = origin.split("://")
  return host === process.env.WSO2_FIORDE_DOMAIN
}