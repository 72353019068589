import React, { useEffect, useState } from 'react'

import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'
import { MainMenuPL } from 'fiorde-fe-components'
import NotFound from './notFound'
import { Root, Title, WelcomeUserName, ItemContainer, Container, Rows } from './style'
import { Roles } from '../../../application/utils/enums/rolesEnum'
import { Modules } from '../../../application/utils/enums/modulesEnum'
import { useAuth } from '../../../application/service/auth'
import { isColgate, isFiorde } from '../../../application/utils/process'

const Home = (): JSX.Element => {
  const [noHasModule, setNoHasModule] = useState(false)
  const history = useHistory()
  const { user, roles } = useAuth()

  const handleClick = (card: any): void => {
    history.push(card.url)
  }

  useEffect(() => {
    if (roles.length === 0) {
      setNoHasModule(true)
    }
  }, [])

  const getRoles = (typeModule): boolean => {
    const origin = window.location.origin
    const [_, host] = origin.split("://")
    return true
  }

  const menuItens: any[] = [
    {
      icon: 'exportation',
      url: '/',
      isActive: isFiorde(),
      key: 'exportation',
      label: I18n.t('pages.home.exportation'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'importation',
      isActive: isFiorde(),
      url: '/',
      key: 'importation',
      label: I18n.t('pages.home.importation'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'freight-forwarder',
      isActive: isFiorde(),
      key: 'freight-forwarder',
      url: '/',
      label: I18n.t('pages.home.freightForwarder'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'commercial',
      url: '/comercial',
      isActive: isFiorde(),
      key: 'commercial',
      label: I18n.t('pages.home.commercial'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'registrations',
      url: '/registrations',
      isActive: isColgate() || isFiorde(),
      key: 'registrations',
      label: 'Cadastros',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'yard-control',
      url: '/yard',
      isActive: isColgate() || isFiorde(),
      key: 'yard',
      label: 'Controle de Pátio',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'checklist',
      url: '/checklist',
      isActive: isColgate() || isFiorde(),
      key: 'checklist',
      label: 'Checklist',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'pu-catp',
      url: '/pu-catp',
      isActive: isFiorde(),
      key: 'pu-catp',
      label: 'Catálogo de Produtos',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'billing',
      isActive: isFiorde(),
      key: 'billing',
      url: '/',
      label: I18n.t('pages.home.billing'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'national-logistic',
      isActive: isFiorde(),
      key: 'national-logistic',
      url: '/',
      label: I18n.t('pages.home.nationalLogistic'),
      onclick: (item) => handleClick(item)
    }
  ]

  return (
    <Root>
      {noHasModule
        ? (
        <NotFound />
          )
        : (
        <>
          <Title>
            <WelcomeUserName>{I18n.t('pages.home.welcome', { name: user?.firstname })}</WelcomeUserName>
          </Title>
          <Container>
            <Rows><ItemContainer>{I18n.t('pages.home.sirius_modules')}</ItemContainer></Rows>
            <Rows><MainMenuPL menuPlItems={menuItens} /></Rows>
          </Container>
        </>
          )}
    </Root>
  )
}
export default Home
