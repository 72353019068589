export function makeConfig() {
  const origin = window.location.origin
  const [_, host] = origin.split("://")

  switch (host) {
    case process.env.WSO2_COLGATE_DOMAIN:
      return ({
        "clientID": process.env.WSO2_COLGATE_CLIENT_ID,
        "clientSecret": process.env.WSO2_COLGATE_CLIENT_SECRET,
        "baseUrl": process.env.WSO2_COLGATE_BASE_URL,
        "signInRedirectURL": process.env.WSO2_COLGATE_SIGN_IN_REDIRECT_URL,
        "signOutRedirectURL": process.env.WSO2_COLGATE_SIGN_OUT_REDIRECT_URL,
        "scope": ["openid"]
      });
    default:
      return ({
        "clientID": process.env.WSO2_FIORDE_CLIENT_ID,
        "clientSecret": process.env.WSO2_FIORDE_CLIENT_SECRET,
        "baseUrl": process.env.WSO2_FIORDE_BASE_URL,
        "signInRedirectURL": process.env.WSO2_FIORDE_SIGN_IN_REDIRECT_URL,
        "signOutRedirectURL": process.env.WSO2_FIORDE_SIGN_OUT_REDIRECT_URL,
        "scope": ["openid"]
      });
  }
 }
