import React, { useState } from 'react'

import { SideMenuContainer, MainContainer, RootContainer, HeaderContainer } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { toggleTheme } from '../../../application/redux/actions/app'
import { SideMenu, Header, DropDownMenu } from 'fiorde-fe-components'
import { useHistory } from 'react-router-dom'
import { setLocale, I18n } from 'react-redux-i18n'
import { emitEvent } from '../../../application/utils/event'

import moment from 'moment'
import { useAuth } from '../../../application/service/auth'
import { isColgate, isFiorde } from '../../../application/utils/process'

const Wrapper = ({ children }: any): JSX.Element => {
  const locale = localStorage.getItem('locale') ?? 'pt'
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useSelector((state: any) => state.app?.theme)
  const [showBI, setShowBI] = useState(false)
  const { logout, user } = useAuth()

  const firstPath = location.hash.split('/')[1] ?? ''
  const isHome = firstPath === ''

  const goHome = (): any => {
    return history.push('/')
  }

  const menuItemsMyAccount = [{ key: 'psswd', label: 'Alterar Senha', onclick: () => { } }]

  const menuItems = [
    {
      icon: 'checklist',
      url: '/',
      isActive: isColgate() || isFiorde(),
      key: 'checklist',
      onclick: () => history.push('/checklist')
    },
    {
      icon: 'pu-catp',
      url: '/',
      isActive: isFiorde(),
      key: 'pu-catp',
      onclick: () => history.push('/pu-catp')
    },
    {
      icon: 'yard-control',
      url: '/',
      isActive: isColgate() || isFiorde(),
      key: 'yard',
      onclick: () => history.push('/yard')
    },
    {
      icon: 'registrations',
      url: '/',
      isActive: isColgate() || isFiorde(),
      key: 'registrations',
      onclick: () => history.push('/registrations')
    },
    {
      icon: 'exportation',
      isActive: isFiorde(),
      url: '/',
      key: 'exportation',
      onclick: () => history.push('/')
    },
    {
      icon: 'importation',
      isActive: isFiorde(),
      url: '/',
      key: 'importacao',
      onclick: () => history.push('/')
    },
    {
      icon: 'freight-forwarder',
      isActive: isFiorde(),
      key: 'carga',
      url: '/',
      onclick: () => history.push('/')
    },
    {
      icon: 'billing',
      isActive: isFiorde(),
      key: 'faturamento',
      url: '/',
      onclick: () => history.push('/')
    },
    {
      icon: 'commercial',
      isActive: isFiorde(),
      key: 'comercial',
      onclick: () => history.push('/comercial')
    },
    {
      icon: 'national-logistic',
      isActive: isFiorde(),
      key: 'national-logistic',
      url: '/',
      onclick: () => history.push('/')
    }
  ]

  const dateFormatDisplay = (locale): string => {
    if (locale === 'pt') {
      moment.locale('pt-br')
    } else {
      moment.locale(locale)
    }

    const dayMonth = moment().format('DD/MMM')
    const hourFormat = moment().format('LT')
    const hour = locale === 'pt' ? `${hourFormat}h` : hourFormat
    return `${dayMonth} - ${hour}`
  }

  const handleTheme = ({ themeLabel }): void => {
    dispatch(toggleTheme(themeLabel))
  }

  const handleLanguage = ({ language }): void => {
    // emitting the locale change to another microfrontend
    emitEvent('locale', { locale: language })
    localStorage.setItem('locale', language)
    dispatch(setLocale(language))
  }

  const handleBI = (): void => {
    setShowBI(!showBI)
  }

  const handleLogout = (): void => {
    void logout()
  }

  return (
    <RootContainer>
      {!isHome && (
        <SideMenuContainer>
          <SideMenu
            itemSelected={firstPath}
            menuItems={menuItems}
            onChange={handleTheme}
            onHome={goHome}
            themeProperty={theme}
          />
        </SideMenuContainer>
      )}
      <HeaderContainer isHome={isHome}>
        <Header
          labelMiddle={dateFormatDisplay(locale)}
          leftLabel=""
          showLogo={true}
          menuItems={{
            onChart: handleBI,
            onGear: () => { },
            profile: {
              name: user?.firstName || '',
              notifications: {
                number: 0,
                onNotification: () => { }
              },
              onProfile: () => { },
              photo: ''
            }
          }}
          onLogo={goHome}
          showGear={false}
          showChart={false}
          showNotifications={false}
        >
          <DropDownMenu
            accountLabel={I18n.t('components.dropDownMenu.account')}
            menuItemsMyAccount={menuItemsMyAccount}
            configLabel={I18n.t('components.dropDownMenu.configuration')}
            exitLabel={I18n.t('components.dropDownMenu.exit')}
            onChange={handleTheme}
            onChangeLanguage={handleLanguage}
            languageLabelMain="PT"
            languageLabelSecondary="EN"
            showLabelLanguage
            onExit={handleLogout}
            themeProperty={theme}
            languageProperty={locale}
            theme={I18n.t('components.dropDownMenu.theme')}
            showMyAccountContainer={false}
          />
        </Header>
      </HeaderContainer>
      <MainContainer isHome={isHome}>{children}</MainContainer>
    </RootContainer>
  )
}

export default Wrapper
